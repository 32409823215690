import React, { Component } from 'react';
import { createClient } from "@supabase/supabase-js";
import './App.css'
const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY);

const sorular = [
        {
            q_id: 500,
            tip: 'klasik',
            soru: {
                tr: "Yaş",
                en: "Age",
                de: "Alter"
            },
            db_field: "yas"
        },
        {
            q_id: 501,
            tip: 'secenek',
            soru: {
                tr: "Cinsiyet",
                en: "Gender",
                de: "Geschlecht"
            },
            db_field: "cinsiyet",
            secenekler: {
                tr: ["Erkek", "Kadın", "Belirtmek İstemiyorum."],
                en: ["Man", "Woman", "Prefer not to say."],
                de: ["Mann", "Frau", "Ich möchte es nicht angeben."]
            }
        },
        {
            q_id: 502,
            tip: 'klasik',
            soru: {
                tr: "Meslek",
                en: "Occupation",
                de: "Beruf"
            },
            db_field: "meslek"
        },
        {
            q_id: 503,
            tip: 'secenek',
            soru: {
                tr: "Eğitim Durumu",
                en: "Education Level",
                de: "Bildungsstand"
            },
            db_field: "egitim_durumu",
            secenekler: {
                tr: ["Okuryazar", "İlkokul", "Ortaokul", "Lise", "Üniversite", "Lisansüstü"],
                en: ["Literate", "Primary School", "Middle School", "High School", "College", "Postgraduate"],
                de: ["Gelesen", "Grundschule", "Hauptschule", "Gymnasium", "Universität", "Postgraduiert"]
            }
        },
        {
            q_id: 504,
            tip: 'secenek',
            soru: {
                tr: "Yaşadığı Ülke",
                en: "Country of Residence",
                de: "Wohnsitzland"
            },
    
            db_field: "yasadigi_ulke",
            secenekler: {
                tr: [
                    "Türkiye",
                    "Afganistan",
                    "Almanya",
                    "Arnavutluk",
                    "Avustralya",
                    "Avusturya",
                    "Azerbaycan",
                    "Bahreyn",
                    "Bangladeş",
                    "Belarus",
                    "Belçika",
                    "Birleşik Arap Emirlikleri",
                    "Birleşik Krallık",
                    "Bolivya",
                    "Bosna-Hersek",
                    "Botsvana",
                    "Brezilya",
                    "Bulgaristan",
                    "Cezayir",
                    "Cibuti",
                    "Çek Cumhuriyeti",
                    "Çin",
                    "Danimarka",
                    "Endonezya",
                    "Eritre",
                    "Ermenistan",
                    "Estonya",
                    "Etiyopya",
                    "Fas",
                    "Fiji",
                    "Fildişi Sahili",
                    "Filipinler",
                    "Finlandiya",
                    "Fransa",
                    "Gabon",
                    "Gambiya",
                    "Gana",
                    "Gine",
                    "Gine-Bissau",
                    "Grenada",
                    "Guatemala",
                    "Güney Afrika",
                    "Güney Kore",
                    "Güney Sudan",
                    "Gürcistan",
                    "Haiti",
                    "Hindistan",
                    "Hollanda",
                    "Honduras",
                    "Irak",
                    "İran",
                    "İrlanda",
                    "İspanya",
                    "İsrail",
                    "İsveç",
                    "İsviçre",
                    "İtalya",
                    "İzlanda",
                    "Jamaika",
                    "Japonya",
                    "Kamboçya",
                    "Kamerun",
                    "Kanada",
                    "Karadağ",
                    "Katar",
                    "Kazakistan",
                    "Kenya",
                    "Kıbrıs",
                    "Kırgızistan",
                    "Kiribati",
                    "Kolombiya",
                    "Komorlar",
                    "Kongo Cumhuriyeti",
                    "Kosova",
                    "Kosta Rika",
                    "Kuveyt",
                    "Küba",
                    "Laos",
                    "Lesotho",
                    "Letonya",
                    "Liberya",
                    "Libya",
                    "Liechtenstein",
                    "Litvanya",
                    "Lübnan",
                    "Lüksemburg",
                    "Macaristan",
                    "Madagaskar",
                    "Makedonya",
                    "Malavi",
                    "Maldivler",
                    "Malezya",
                    "Mali",
                    "Malta",
                    "Marshall Adaları",
                    "Mauritius",
                    "Meksika",
                    "Mısır",
                    "Moğolistan",
                    "Moldova",
                    "Monako",
                    "Moritanya",
                    "Moritus",
                    "Mozambik",
                    "Myanmar (Burma)",
                    "Namibya",
                    "Nauru",
                    "Nepal",
                    "Nijer",
                    "Nijerya",
                    "Nikaragua",
                    "Norveç",
                    "Orta Afrika Cumhuriyeti",
                    "Özbekistan",
                    "Pakistan",
                    "Palau",
                    "Panama",
                    "Papua Yeni Gine",
                    "Paraguay",
                    "Peru",
                    "Polonya",
                    "Portekiz",
                    "Romanya",
                    "Ruanda",
                    "Rusya",
                    "Saint Kitts ve Nevis",
                    "Saint Lucia",
                    "Saint Vincent ve Grenadinler",
                    "Samoa",
                    "San Marino",
                    "Sao Tome ve Principe",
                    "Senegal",
                    "Seyşeller",
                    "Sırbistan",
                    "Sierra Leone",
                    "Singapur",
                    "Slovakya",
                    "Slovenya",
                    "Solomon Adaları",
                    "Somali",
                    "Sri Lanka",
                    "Sudan",
                    "Surinam",
                    "Suriye",
                    "Suudi Arabistan",
                    "Svaziland",
                    "Tacikistan",
                    "Tanzanya",
                    "Tayland",
                    "Tayvan",
                    "Togo",
                    "Tonga",
                    "Trinidad ve Tobago",
                    "Tunus",
                    "Tuvalu",
                    "Türkmenistan",
                    "Uganda",
                    "Ukrayna",
                    "Umman",
                    "Uruguay",
                    "Ürdün",
                    "Vanuatu",
                    "Vatikan",
                    "Venezuela",
                    "Vietnam",
                    "Yemen",
                    "Yeni Zelanda",
                    "Yunanistan",
                    "Zambiya",
                    "Zimbabve"
                ],
                en: [
                    "Turkey",
                    "Afghanistan",
                    "Germany",
                    "Albania",
                    "Australia",
                    "Austria",
                    "Azerbaijan",
                    "Bahrain",
                    "Bangladesh",
                    "Belarus",
                    "Belgium",
                    "United Arab Emirates",
                    "United Kingdom",
                    "Bolivia",
                    "Bosnia and Herzegovina",
                    "Botswana",
                    "Brazil",
                    "Bulgaria",
                    "Algeria",
                    "Djibouti",
                    "Czech Republic",
                    "China",
                    "Denmark",
                    "Indonesia",
                    "Eritrea",
                    "Armenia",
                    "Estonia",
                    "Ethiopia",
                    "Morocco",
                    "Fiji",
                    "Ivory Coast",
                    "Philippines",
                    "Finland",
                    "France",
                    "Gabon",
                    "Gambia",
                    "Ghana",
                    "Guinea",
                    "Guinea-Bissau",
                    "Grenada",
                    "Guatemala",
                    "South Africa",
                    "South Korea",
                    "South Sudan",
                    "Georgia",
                    "Haiti",
                    "India",
                    "Netherlands",
                    "Honduras",
                    "Iraq",
                    "Iran",
                    "Ireland",
                    "Spain",
                    "Israel",
                    "Sweden",
                    "Switzerland",
                    "Italy",
                    "Iceland",
                    "Jamaica",
                    "Japan",
                    "Cambodia",
                    "Cameroon",
                    "Canada",
                    "Montenegro",
                    "Qatar",
                    "Kazakhstan",
                    "Kenya",
                    "Cyprus",
                    "Kyrgyzstan",
                    "Kiribati",
                    "Colombia",
                    "Comoros",
                    "Republic of the Congo",
                    "Kosovo",
                    "Costa Rica",
                    "Kuwait",
                    "Cuba",
                    "Laos",
                    "Lesotho",
                    "Latvia",
                    "Liberia",
                    "Libya",
                    "Liechtenstein",
                    "Lithuania",
                    "Lebanon",
                    "Luxembourg",
                    "Hungary",
                    "Madagascar",
                    "Macedonia",
                    "Malawi",
                    "Maldives",
                    "Malaysia",
                    "Mali",
                    "Malta",
                    "Marshall Islands",
                    "Mauritius",
                    "Mexico",
                    "Egypt",
                    "Mongolia",
                    "Moldova",
                    "Monaco",
                    "Mauritania",
                    "Moritus",
                    "Mozambique",
                    "Myanmar (Burma)",
                    "Namibia",
                    "Nauru",
                    "Nepal",
                    "Niger",
                    "Nigeria",
                    "Nicaragua",
                    "Norway",
                    "Central African Republic",
                    "Uzbekistan",
                    "Pakistan",
                    "Palau",
                    "Panama",
                    "Papua New Guinea",
                    "Paraguay",
                    "Peru",
                    "Poland",
                    "Portugal",
                    "Romania",
                    "Rwanda",
                    "Russia",
                    "Saint Kitts and Nevis",
                    "Saint Lucia",
                    "Saint Vincent and the Grenadines",
                    "Samoa",
                    "San Marino",
                    "Sao Tome and Principe",
                    "Senegal",
                    "Seychelles",
                    "Serbia",
                    "Sierra Leone",
                    "Singapore",
                    "Slovakia",
                    "Slovenia",
                    "Solomon Islands",
                    "Somalia",
                    "Sri Lanka",
                    "Sudan",
                    "Suriname",
                    "Syria",
                    "Saudi Arabia",
                    "Swaziland",
                    "Tajikistan",
                    "Tanzania",
                    "Thailand",
                    "Taiwan",
                    "Togo",
                    "Tonga",
                    "Trinidad and Tobago",
                    "Tunisia",
                    "Tuvalu",
                    "Turkmenistan",
                    "Uganda",
                    "Ukraine",
                    "Oman",
                    "Uruguay",
                    "Jordan",
                    "Vanuatu",
                    "Vatican",
                    "Venezuela",
                    "Vietnam",
                    "Yemen",
                    "New Zealand",
                    "Greece",
                    "Zambia",
                    "Zimbabwe"
                ],
                de: [
                    "Türkei",
                    "Afghanistan",
                    "Deutschland",
                    "Albanien",
                    "Australien",
                    "Österreich",
                    "Aserbaidschan",
                    "Bahrain",
                    "Bangladesch",
                    "Weißrussland",
                    "Belgien",
                    "Vereinigte Arabische Emirate",
                    "Vereinigtes Königreich",
                    "Bolivien",
                    "Bosnien und Herzegowina",
                    "Botswana",
                    "Brasilien",
                    "Bulgarien",
                    "Algerien",
                    "Dschibuti",
                    "Tschechische Republik",
                    "China",
                    "Dänemark",
                    "Indonesien",
                    "Eritrea",
                    "Armenien",
                    "Estland",
                    "Äthiopien",
                    "Marokko",
                    "Fidschi",
                    "Elfenbeinküste",
                    "Philippinen",
                    "Finnland",
                    "Frankreich",
                    "Gabun",
                    "Gambia",
                    "Ghana",
                    "Guinea",
                    "Guinea-Bissau",
                    "Grenada",
                    "Guatemala",
                    "Südafrika",
                    "Südkorea",
                    "Südsudan",
                    "Georgien",
                    "Haiti",
                    "Indien",
                    "Niederlande",
                    "Honduras",
                    "Irak",
                    "Iran",
                    "Irland",
                    "Spanien",
                    "Israel",
                    "Schweden",
                    "Schweiz",
                    "Italien",
                    "Island",
                    "Jamaika",
                    "Japan",
                    "Kambodscha",
                    "Kamerun",
                    "Kanada",
                    "Montenegro",
                    "Katar",
                    "Kasachstan",
                    "Kenia",
                    "Zypern",
                    "Kirgisistan",
                    "Kiribati",
                    "Kolumbien",
                    "Komoren",
                    "Republik Kongo",
                    "Kosovo",
                    "Costa Rica",
                    "Kuwait",
                    "Kuba",
                    "Laos",
                    "Lesotho",
                    "Lettland",
                    "Liberia",
                    "Libyen",
                    "Liechtenstein",
                    "Litauen",
                    "Libanon",
                    "Luxemburg",
                    "Ungarn",
                    "Madagaskar",
                    "Mazedonien",
                    "Malawi",
                    "Malediven",
                    "Malaysia",
                    "Mali",
                    "Malta",
                    "Marshallinseln",
                    "Mauritius",
                    "Mexiko",
                    "Ägypten",
                    "Mongolei",
                    "Moldawien",
                    "Monaco",
                    "Mauretanien",
                    "Moritus",
                    "Mosambik",
                    "Myanmar (Burma)",
                    "Namibia",
                    "Nauru",
                    "Nepal",
                    "Niger",
                    "Nigeria",
                    "Nicaragua",
                    "Norwegen",
                    "Zentralafrikanische Republik",
                    "Usbekistan",
                    "Pakistan",
                    "Palau",
                    "Panama",
                    "Papua-Neuguinea",
                    "Paraguay",
                    "Peru",
                    "Polen",
                    "Portugal",
                    "Rumänien",
                    "Ruanda",
                    "Russland",
                    "Saint Kitts und Nevis",
                    "Saint Lucia",
                    "St. Vincent und die Grenadinen",
                    "Samoa",
                    "San Marino",
                    "São Tomé und Príncipe",
                    "Senegal",
                    "Seychellen",
                    "Serbien",
                    "Sierra Leone",
                    "Singapur",
                    "Slowakei",
                    "Slowenien",
                    "Salomonen",
                    "Somalia",
                    "Sri Lanka",
                    "Sudan",
                    "Suriname",
                    "Syrien",
                    "Saudi-Arabien",
                    "Swasiland",
                    "Tadschikistan",
                    "Tansania",
                    "Thailand",
                    "Taiwan",
                    "Togo",
                    "Tonga",
                    "Trinidad und Tobago",
                    "Tunesien",
                    "Tuvalu",
                    "Turkmenistan",
                    "Uganda",
                    "Ukraine",
                    "Oman",
                    "Uruguay",
                    "Jordanien",
                    "Vanuatu",
                    "Vatikanstadt",
                    "Venezuela",
                    "Vietnam",
                    "Jemen",
                    "Neuseeland",
                    "Griechenland",
                    "Sambia",
                    "Simbabwe"
                ]
            }
        },
    {
            q_id: 505,
            tip: 'klasik',
            soru: {
                tr: "Yaşadığı Şehir",
                en: "City of Residence",
                de: "Wohnort"
            },
            db_field: "yasadigi_sehir"
        },
        {
            q_id: 506,
            tip: 'secenek',
            soru: {
                tr: "Medeni Durumu",
                en: "Marital Status",
                de: "Familienstand"
            },
            db_field: "medeni_durumu",
            secenekler: {
                tr: ["Bekar", "Evli", "Nişanlı", "Boşanmış", "Dul"],
                en: ["Single", "Married", "Engaged", "Divorced", "Widowed"],
                de: ["Ledig", "Verheiratet", "Verlobt", "Geschieden", "Verwitwet"]
            }
        },
        {
            q_id: 1,
            required: true,
            tip: 'secenek',
            soru: {
                tr: "1. Aracınızın yakıt tipi Nedir?",
                en: "1. What is the fuel type of your vehicle?",
                de: "1. Was ist die Kraftstoffart Ihres Fahrzeugs?"
            },
            db_field: "yakit_tipi",
            secenekler: {
                tr: ["Elektrikli Araç", "Fosil Yakıtlı Araç"],
                en: ["Electric Vehicle", "Internal Combustion Engine Vehicle"],
                de: ["Elektroauto", "Fahrzeug mit Verbrennungsmotor"]
            }
        },
        {
            q_id: 2,
            tip: 'secenek',
            soru: {
                tr: "2. Ortalama yıllık araç kullanımınız nedir?",
                en: "2. What is your average annual vehicle usage?",
                de: "2. Was ist Ihre durchschnittliche jährliche Fahrzeugnutzung?"
            },
            db_field: "ortalama_kullanim",
            secenekler: {
                tr: ["10.000 KM'den az", "10.000-30.000 KM", "30.000 KM'den fazla"],
                en: ["Less than 10,000 KM", "10,000-30,000 KM", "More than 30,000 KM"],
                de: ["Weniger als 10.000 KM", "10.000-30.000 KM", "Mehr als 30.000 KM"]
            }
        },
        {
            q_id: 3,
            tip: 'klasik',
            soru: {
                tr: "3. Evinizde kaç kişi yaşıyor?",
                en: "3. How many people live in your household?",
                de: "3. Wie viele Personen leben in Ihrem Haushalt?"
            },
            db_field: "evdeki_kisi_sayisi"
        },
        {
            q_id: 4,
            tip: 'klasik',
            soru: {
                tr: "4. Kaç aracınız var?",
                en: "4. How many vehicles do you have?",
                de: "4. Wie viele Fahrzeuge besitzen Sie?"
            },
            db_field: "evdeki_araba_sayisi"
        },
        {
            q_id: 5,
            tip: 'klasik',
            soru: {
                tr: "5. Kullandığınız araç veya araçlar hangi marka ve model?",
                en: "5. What are the brand and model of the vehicle(s) you use?",
                de: "5. Welche Marke und Modell des/des Fahrzeugs/Fahrzeuge verwenden Sie?"
            },
            db_field: "kullanilan_arac"
        },
        {
            q_id: 6,
            tip: 'secenek',
            soru: {
                tr: "6. Bu aracı tercih etmenizdeki öncü sebebiniz nedir?",
                en: "6. What is the primary reason for choosing this vehicle?",
                de: "6. Was ist der Hauptgrund für die Wahl dieses Fahrzeugs?"
            },
            db_field: "arac_tercih_sebep",
            secenekler: {
                tr: ["Donanım Özellikleri", "Güvenlik", "Tasarım", "Menzil", "Araç Fiyatı", "Sürdürülebilirlik", "Performans"],
                en: ["Features", "Safety", "Design", "Range", "Price", "Sustainability", "Performance"],
                de: ["Ausstattungsmerkmale", "Sicherheit","Design", "Reichweite", "Preis", "Nachhaltigkeit", "Leistung"]
            }
        },
        {
            q_id: 7,
            tip: 'secenek',
            soru: {
                tr: "7. Aracınızı almadan önce bir elektrikli araç deneyiminiz oldu mu?",
                en: "7. Have you had any experience with electric vehicles before purchasing?",
                de: "7. Hatten Sie vor dem Kauf Erfahrung mit Elektrofahrzeugen?"
            },
            db_field: "deneyim",
            secenekler: {
                tr: ["Evet", "Hayır"],
                en: ["Yes", "No"],
                de: ["Ja", "Nein"]
            }
        },
        {
            q_id: 8,
            required: true,
            tip: 'radio',
            soru: {
                "tr": "8. Elektrikli araçların çevresel etkileri hakkında ne düşünüyorsunuz?",
                "en": "8. What do you think about the environmental impact of electric vehicles?",
                "de": "8. Was halten Sie von den Umweltauswirkungen von Elektrofahrzeugen?"
            },
            db_field: "cevresel_etki",
            detail: {
                "leftText": {
                    "tr": "Çok Olumsuz",
                    "en": "Very Negative",
                    "de": "Sehr negativ"
                },
                "rightText": {
                    "tr": "Çok Olumlu",
                    "en": "Very Positive",
                    "de": "Sehr positiv"
                },
                "radioLength": 5
            }
        },
        {
            q_id: 9,
            tip: 'radio',
            soru: {
                "tr": "9. Elektrikli araçların kaza anındaki güvenilirliği hakkında ne düşünüyorsunuz?",
                "en": "9. What do you think about the safety reliability of electric vehicles in accidents?",
                "de": "9. Was denken Sie über die Sicherheitszuverlässigkeit von Elektrofahrzeugen bei Unfällen?"
            },
            db_field: "güvenilirlik",
            detail: {
                "leftText": {
                    "tr": "Çok Güvensiz",
                    "en": "Very Unsafe",
                    "de": "Sehr unsicher"
                },
                "rightText": {
                    "tr": "Çok Güvenli",
                    "en": "Very Safe",
                    "de": "Sehr sicher"
                },
                "radioLength": 5
            }
        },
        {
            q_id: 10,
            tip: 'radio',
            soru: {
                tr: "10. Elektrikli araçların tasarımları hakkında ne düşünüyorsunuz?",
                en: "10. What do you think about the designs of electric vehicles?",
                de: "10. Was denken Sie über das Design von Elektrofahrzeugen?"
            },
            db_field: "tasarim_gorus",
            detail: {
                leftText: {
                    tr: "Çok Olumsuz",
                    en: "Very Negative",
                    de: "Sehr negativ"
                },
                rightText: {
                    tr: "Çok Olumlu",
                    en: "Very Positive",
                    de: "Sehr positiv"
                },
                radioLength: 5
            }
        },
        {
            q_id: 11,
            tip: 'radio',
            soru: {
                tr: "11. Elektrikli araçların ekonomik etkileri hakkında ne düşünüyorsunuz?",
                en: "11. What do you think about the economic effects of electric vehicles?",
                de: "11. Was denken Sie über die wirtschaftlichen Auswirkungen von Elektrofahrzeugen?"
            },
            db_field: "ekonomik_etki",
            detail: {
                leftText: {
                    tr: "Çok Olumsuz",
                    en: "Very Negative",
                    de: "Sehr negativ"
                },
                rightText: {
                    tr: "Çok Olumlu",
                    en: "Very Positive",
                    de: "Sehr positiv"
                },
                radioLength: 5
            }
        },
        {
            q_id: 12,
            tip: 'radio',
            soru: {
                tr: "12. Ülkenizdeki elektrikli araç altyapısı hakkındaki görüşleriniz?",
                en: "12. What do you think about the infrastructure for electric vehicles in your country?",
                de: "12. Was halten Sie von der Infrastruktur für Elektrofahrzeuge in Ihrem Land?"
            },
            db_field: "altyapi_gorus",
            detail: {
                leftText: {
                    tr: "Çok Kötü",
                    en: "Very Poor",
                    de: "Sehr schlecht"
                },
                rightText: {
                    tr: "Çok İyi",
                    en: "Very Good",
                    de: "Sehr gut"
                },
                radioLength: 5
            }
        },
        {
            q_id: 13,
            tip: 'radio',
            soru: {
                tr: "13. Ülkenizdeki elektrikli araç teşvik sistemi hakkındaki görüşleriniz?",
                en: "13. What do you think about the incentive system for electric vehicles in your country?",
                de: "13. Was halten Sie von dem Anreizsystem für Elektrofahrzeuge in Ihrem Land?"
            },
            db_field: "tesvik_gorus",
            detail: {
                leftText: {
                    tr: "Çok Kötü",
                    en: "Very Poor",
                    de: "Sehr schlecht"
                },
                rightText: {
                    tr: "Çok İyi",
                    en: "Very Good",
                    de: "Sehr gut"
                },
                radioLength: 5
            }
        },
        {
            q_id: 14,
            tip: 'radio',
            soru: {
                tr: "14. Elektrikli araçların gelecekteki yaygınlığı hakkında ne düşünüyorsunuz?",
                en: "14. What do you think about the future adoption of electric vehicles?",
                de: "14. Was denken Sie über die zukünftige Verbreitung von Elektrofahrzeugen?"
            },
            db_field: "yayginlik_gorus",
            detail: {
                leftText: {
                    tr: "Hiç yaygın olmayacak",
                    en: "Will not be widespread at all",
                    de: "Wird überhaupt nicht weit verbreitet sein"
                },
                rightText: {
                    tr: "Çok yaygın olacak",
                    en: "Will be very widespread",
                    de: "Wird sehr weit verbreitet sein"
                },
                radioLength: 5
            }
        },
        {
            q_id: 15,
            tip: 'secenek',
            soru: {
                tr: "15. Araç kullanım amacınız genelde hangi yöndedir?",
                en: "15. What is the general purpose of your vehicle usage?",
                de: "15. Was ist der allgemeine Zweck Ihrer Fahrzeugnutzung?"
            },
            db_field: "kullanim_amaci",
            secenekler: {
                tr: ["Günlük işe gidip gelme", "Serbest zaman etkinlikleri", "Tatil/Haftasonu gezileri", "Uzun yolculuklar", "Kısa süreli rutin işler", "Ticari kullanım"],
                en: ["Daily commute", "Leisure activities", "Holiday/Weekend trips", "Long journeys", "Short-term routine tasks", "Commercial usage"],
                de: ["Täglicher Arbeitsweg", "Freizeitaktivitäten", "Urlaub/Wochenendausflüge", "Lange Reisen", "Kurzfristige Routineaufgaben", "Kommerzielle Nutzung"]
            }
        },
        {
            q_id: 16,
            tip: 'secenek',
            soru: {
                tr: "16. Aracınızın batarya büyüklüğü nedir?",
                en: "16. What is the battery size of your vehicle?",
                de: "16. Wie groß ist die Batterie Ihres Fahrzeugs?"
            },
            db_field: "batarya_büyüklüğü",
            secenekler: {
                tr: ["50kW/saat'ten az", "50-70kW/saat", "70-100kW/saat", "100kW/saat'den faz"],
                en: ["Less than 50kW/h", "50-70kW/h", "70-100kW/h", "More than 100kW/h"],
                de: ["Weniger als 50 kW/h", "50-70 kW/h", "70-100 kW/h", "Mehr als 100 kW/h"]
            }
        },
        {
            q_id: 17,
            tip: 'secenek',
            soru: {
                tr: "17. Elektrikli aracınızı ne sıklıkla şarj ediyorsunuz?",
                en: "17. How often do you charge your electric vehicle?",
                de: "17. Wie oft laden Sie Ihr Elektrofahrzeug auf?"
            },
            db_field: "sarj_sikligi",
            secenekler: {
                tr: ["Her gün", "Haftada birkaç kez", "Haftada bir kez", "Ayda birkaç kez", "Ayda bir kez"],
                en: ["Every day", "Several times a week", "Once a week", "Several times a month", "Once a month"],
                de: ["Jeden Tag", "Mehrere Male pro Woche", "Einmal pro Woche", "Mehrere Male pro Monat", "Einmal im Monat"]
            }
        },
        {
            q_id: 18,
            tip: 'secenek',
            soru: {
                tr: "18. Elektrikli aracınızı genellikle nerede şarj ediyorsunuz?",
                en: "18. Where do you primarily charge your electric vehicle?",
                de: "18. Wo laden Sie Ihr Elektrofahrzeug hauptsächlich auf?"
            },
            db_field: "sarj_yeri",
            secenekler: {
                tr: ["Evde", "İş yerinde", "Kamusal şarj istasyonlarında", "Diğer (Lütfen belirtiniz)"],
                en: ["At home", "At work", "At public charging stations", "Other (Please specify)"],
                de: ["Zu Hause", "Bei der Arbeit", "An öffentlichen Ladestationen", "Andere (Bitte angeben)"]
            }
        },
        {
            q_id: 19,
            tip: 'klasik',
            soru: {
                tr: "19. Elektrikli araç kullanmanın en büyük avantajı nedir?",
                en: "19. What is the biggest advantage of using an electric vehicle?",
                de: "19. Was ist der größte Vorteil der Verwendung eines Elektrofahrzeugs?"
            },
            db_field: "avantaj"
        },
        {
            q_id: 20,
            tip: 'klasik',
            soru: {
                tr: "20. Elektrikli araç kullanmanın en büyük dezavantajı nedir?",
                en: "20. What is the biggest disadvantage of using an electric vehicle?",
                de: "20. Was ist der größte Nachteil der Verwendung eines Elektrofahrzeugs?"
            },
            db_field: "dezavantaj"
        },        
        {
            q_id: 21,
            tip: 'secenek',
            soru: {
                tr: "21. Elektrikli araçları tercih etmenizin başlıca sebebi nedir?",
                en: "21. What is the main reason for choosing electric vehicles?",
                de: "21. Was ist der Hauptgrund für die Wahl von Elektrofahrzeugen?"
            },
            db_field: "tercih_sebep",
            secenekler: {
                tr: ["Çevre dostu olmaları", "Yüksek performans, sessizlik ve sıfır-emisyon sürüş deneyimi", "Uzun vadede daha ekonomik olmaları", "Yenilikçi teknolojiye olan ilgi", "Hükümet teşvikleri ve vergi avantajları"],
                en: ["Being environmentally friendly", "High performance, silence, and zero-emission driving experience", "Long-term cost-effectiveness", "Interest in innovative technology", "Government incentives and tax benefits"],
                de: ["Umweltfreundlich sein", "Hohe Leistung, Stille und Fahrerlebnis ohne Emissionen", "Langfristige Wirtschaftlichkeit", "Interesse an innovativer Technologie", "Regierungsanreize und Steuervorteile"]
            }
        },
        {
            q_id: 22,
            tip: 'secenek',
            soru: {
                tr: "22. Elektrikli araçları tercih etmemenizin başlıca sebebi nedir?",
                en: "22. What is your primary reason for not choosing electric vehicles?",
                de: "22. Was ist Ihr Hauptgrund, Elektrofahrzeuge nicht zu wählen?"
            },
            db_field: "tercih_etmeme_sebep",
            secenekler: {
                tr: ["Menzil endişesi", "Şarj altyapısının yetersizliği", "Elektrikli araç fiyatları", "Mevcut fosil yakıtlı aracınızın performansı", "Elektrikli araçların teknolojik güvenilirliği hakkındaki endişeler"],
                en: ["Range anxiety", "Inadequate charging infrastructure", "Electric vehicle prices", "Performance of current fossil fuel vehicle", "Concerns about technological reliability of electric vehicles"],
                de: ["Reichweitenangst", "Unzureichende Ladeinfrastruktur", "Preise für Elektrofahrzeuge", "Leistung des aktuellen Fahrzeugs mit fossilem Brennstoff", "Sorgen um die technologische Zuverlässigkeit von Elektrofahrzeugen"]
            }
        },
        {
            q_id: 23,
            tip: 'secenek',
            soru: {
                tr: "23. Tercih etmeme sebebinizi göz önünde bulundurarak elektrikli araçların potansiyel avantajları hakkındaki görüşleriniz nelerdir?",
                en: "23. Considering your reason for not choosing, what are your views on potential advantages of electric vehicles?",
                de: "23. Was sind Ihre Ansichten zu den potenziellen Vorteilen von Elektrofahrzeugen unter Berücksichtigung Ihres Grundes für die Nichtwahl?"
            },
            db_field: "potansiyel_avantaj",
            secenekler: {
                tr: ["Donanım özellikleri", "Güvenlik", "Menzil artışı", "Daha düşük işletme maliyetleri", "Daha az bakım gerektirmesi"],
                en: ["Hardware features", "Safety", "Increased range", "Lower operating costs", "Less maintenance required"],
                de: ["Hardware-Funktionen", "Sicherheit", "Erhöhte Reichweite", "Niedrigere Betriebskosten", "Weniger Wartung erforderlich"]
            }
        },
        {
            q_id: 24,
            tip: "secenek",
            soru: {
                tr: "24. Elektrikli araçlarla ilgili düşüncelerinizde değişime yol açabilecek şartlar nelerdir?",
                en: "24. What conditions could lead to a change in your thoughts about electric vehicles?",
                de: "24. Welche Bedingungen könnten zu einer Änderung Ihrer Gedanken über Elektrofahrzeuge führen?"
            },
            db_field: "degisim_sartlari",
            secenekler: {
                tr: ["Elektrikli araçların menzilinin artması", "Şarj altyapısının genişlemesi ve geliştirilmesi", "Elektrikli araçların fiyatlarının düşmesi veya teşviklerin artması", "Elektrikli araç teknolojisinin daha fazla bilinirlik kazanması ve toplumda kabul görmesi", "Elektrikli araçların kullanımının çevresel etkileri konusunda daha fazla farkındalık ve eğitim"],
                en: ["Increased range of electric vehicles", "Expansion and improvement of charging infrastructure", "Decrease in electric vehicle prices or increase in incentives", "Greater awareness and societal acceptance of electric vehicle technology", "More awareness and education about the environmental impacts of electric vehicle use"],
                de: ["Erhöhte Reichweite von Elektrofahrzeugen", "Erweiterung und Verbesserung der Ladeinfrastruktur", "Preissenkung für Elektrofahrzeuge oder Erhöhung von Anreizen", "Größeres Bewusstsein und gesellschaftliche Akzeptanz der Elektrofahrzeugtechnologie", "Mehr Bewusstsein und Bildung über die Umweltauswirkungen der Nutzung von Elektrofahrzeugen"]
            }
        },
        {
            q_id: 25,
            tip: 'klasik',
            soru: {
                tr: "25. Anketimize katıldığınız için teşekkür ederiz! Son olarak eklemek istediğiniz bir şey var mı?",
                en: "25. Thank you for participating in our survey! Is there anything else you would like to add?",
                de: "25. Vielen Dank, dass Sie an unserer Umfrage teilgenommen haben! Möchten Sie noch etwas hinzufügen?"
            },
            db_field: "eklemek_istedikleriniz"
        }
    ]        

const electricSelectedHiddenQuestions = [22, 23, 24];
const fosilSelectedHiddenQuestions = [16, 17, 18, 19, 20, 21];



export default class Anket extends Component {
    constructor(props) {
        super(props);
        const initialState = { lang: "de" };
        sorular.forEach((soru) => {
            initialState[soru.db_field] = null;
        });

        this.state = initialState;
    }

    componentDidMount(){
        if (window.location.pathname) {
            let dpathname = window.location.pathname;
            let pathname = dpathname.replace(/^\//, '');
            if (pathname == "de") {
                this.setState({lang : "de"})
            }else if (pathname == "en") {
                this.setState({lang : "en"}) 
            }else{
               this.setState({lang : "tr"})
            }
        }
    }


    handleChangeSoru = (e, index) => {
        const { value } = e.target;
        const dbField = sorular[index].db_field;
        // Update the state based on the selected value in "yakit_tipi"
        if (dbField === "yakit_tipi") {
            const shouldHideQuestions = value === "Elektrikli Araç";

            const updatedState = {
                [dbField]: value,
            };


            if (value == "Elektrikli Araç" || value == "Electric Vehicle") {
                electricSelectedHiddenQuestions.forEach((qId) => {
                    updatedState[`q_${qId}`] = null;
                });
                fosilSelectedHiddenQuestions.forEach((qId) => {
                    updatedState[`q_${qId}`] = "";
                });
            } else if (value == "Fosil Yakıtlı Araç" || value == "Internal Combustion Engine Vehicle") {
                electricSelectedHiddenQuestions.forEach((qId) => {
                    updatedState[`q_${qId}`] = "";
                });
                fosilSelectedHiddenQuestions.forEach((qId) => {
                    updatedState[`q_${qId}`] = null;
                });
            }



            this.setState((prevState) => ({
                ...prevState,
                ...updatedState,
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                [dbField]: value,
            }));
        }
    };


    handleSubmit = async (e) => {
        e.preventDefault();
        await sorular.forEach(async (soru, index) => {
            if (!this.state[soru.db_field]) {
                await this.setState((prevState) => ({
                    ...prevState,
                    [soru.db_field]: '',
                }));
            }

        });

        const filteredState = { ...this.state };



        electricSelectedHiddenQuestions.forEach(id => {
            delete filteredState[`q_${id}`];
        });

        fosilSelectedHiddenQuestions.forEach(id => {
            delete filteredState[`q_${id}`];
        });


        delete filteredState.lang;






        const eklenenVeri = await supabase
            .from('surveys')
            .insert([filteredState]);
        if (eklenenVeri.error) {            
            alert("Anket Gönderilemedi !");
        } else {
            alert("Anket başarıyla gönderildi.");
            window.location.reload();
        }
    };




    renderSoruInput = (soru, index) => {
        if (soru.tip === 'klasik') {
            return (
                <div key={index} className='question-container' style={{ display: this.state[`q_${soru.q_id}`] !== null ? "block" : "none" }} >

                    <div className='question-question'>{soru.soru[this.state.lang]}</div>
                    <input
                        required={soru?.required}
                        type={soru.db_field === "yas" ? "number" : "text"}
                        value={this.state[soru.db_field] || ''}
                        onChange={(e) => this.handleChangeSoru(e, index)}
                    />

                </div>
            );
        } else if (soru.tip === 'secenek') {
            return (
                <div key={index} className='question-container' style={{ display: this.state[`q_${soru.q_id}`] !== null ? "block" : "none" }}>
                    <div className='question-question'
                    >{soru.soru[this.state.lang]}</div>
                    <select
                        required={soru?.required}
                        value={this.state[soru.db_field] || ''}
                        onChange={(e) => this.handleChangeSoru(e, index)}
                    >


                        <option value="">Seçiniz</option>
                        {
                            soru.secenekler[this.state.lang].map((secenek, secenekIndex) => (
                                <option key={secenekIndex} value={secenek}>{secenek}</option>
                            ))}
                    </select>
                </div>
            );
        } else if (soru.tip === 'radio') {
            return (
                <div key={index} className='question-container' style={{ display: this.state[`q_${soru.q_id}`] !== null ? "block" : "none" }}>
                    <div className='question-question'>{soru.soru[this.state.lang]}</div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "flex-end",
                    }}>
                        <div>{soru.detail.leftText[this.state.lang]}</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}>
                            {Array.from({ length: soru.detail.radioLength }).map((value, radioIndex) => (
                                <label key={radioIndex} style={{ marginRight: 20, textAlign: "center", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", gap: 10 }}>
                                    <div>{radioIndex + 1}</div>
                                    <input
                                        required={soru?.required}

                                        style={{ width: 17, height: 17 }}
                                        type="radio"
                                        name={`radio_${index}`}
                                        value={radioIndex + 1}
                                        checked={parseInt(this.state[soru.db_field]) === radioIndex + 1}
                                        onChange={(e) => this.handleChangeSoru(e, index)}
                                    />

                                </label>
                            ))}
                        </div>
                        <div>{soru.detail.rightText[this.state.lang]}</div>

                    </div>
                </div>
            );
        }
        return null;
    };

    render() {
        return (
            <div className="container">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: 10 }}>
                    <>

                        {
                            this.state.lang == "tr" ?


                                <>
                                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                        <h1>Fosil ve Elektrikli Araçların Kullanımının Karşılaştırılarak Ekonomik, Sürdürülebilirlik Analizi</h1>

                                        Sayın Katılımcı,<br></br>

                                        Değerli zamanınızı ayırıp bu anketi doldurduğunuz için teşekkür ederiz. Bu anket, Türkiye ve Avrupa'da fosil ve elektrikli araç kullanım alışkanlıklarını ve bu kullanımın ekonomik ve sürdürülebilirlik açısından analizini yapmak amacıyla hazırlanmıştır.
                                        Anket sonuçları, "Fosil ve Elektrikli Araçların Kullanımının Türkiye ve Avrupa'da Karşılaştırılarak Ekonomik, Sürdürülebilirlik Analizi" başlıklı tezin bir parçası olarak kullanılacaktır.
                                        Bu araştırma, fosil ve elektrikli araç kullanımının ekonomik ve çevresel etkilerini anlamamıza yardımcı olacak önemli bir veri kaynağı oluşturacaktır.
                                        Anket sonuçlarının 2025 itibariyle bu platformda yayınlanacağını belirtmek isteriz.Lütfen verdiğiniz yanıtların gizli tutulacağını ve sadece araştırma amaçları için kullanılacağını unutmayın. Katkılarınız büyük önem taşımaktadır.<br></br>
                                        Tekrar teşekkür ederiz.<br></br>
                                        <b>Araştırmacı:</b> Mekatronik Mühendisi Ercan TURGUT <br></br>
                                        <b>Danışman:</b> Dr. Öğretim Üyesi Melik Ziya YAKUT


                                    </div>

                                </>
                                : this.state.lang == "en"?
                                <>
                                    <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                        <h1>Comparative Economic and Sustainability Analysis of Fossil and Electric Vehicle Usage</h1>

                                        Dear Participant,<br></br>

                                        Thank you for taking the time to fill out this survey. This survey has been prepared to examine the usage patterns of fossil and electric vehicles in Turkey and Europe, and to analyze their economic and sustainability aspects.

                                        The survey results will be used as part of the thesis titled "Comparative Economic and Sustainability Analysis of Fossil and Electric Vehicle Usage in Turkey and Europe." This research will serve as an important data source to understand the economic and environmental impacts of fossil and electric vehicle usage.

                                        Please note that the survey responses will be kept confidential and used only for research purposes.

                                        We would like to inform you that the survey results will be published on this platform in 2025.<br></br>

                                        Thank you again for your valuable contribution.<br></br>
                                        <b>Researcher:</b> Mechatroniker-Ingenieur Ercan TURGUT <br></br>
                                        <b>Supervisor:</b> Associate Professor Melik Ziya YAKUT



                                    </div>
                                </> :

                                <>
                                <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                    <h1>Vergleichende Wirtschafts- und Nachhaltigkeitsanalyse der Nutzung von fossilen und elektrischen Fahrzeugen</h1>

                                    Sehr geehrte Teilnehmerin, sehr geehrter Teilnehmer,<br></br>

                                    Vielen Dank, dass Sie sich die Zeit genommen haben, diesen Fragebogen auszufüllen. Dieser Fragebogen wurde erstellt, um die Nutzungsmuster von fossilen und elektrischen Fahrzeugen in der Türkei und Europa zu untersuchen und ihre wirtschaftlichen und nachhaltigen Aspekte zu analysieren.

                                    Die Umfrageergebnisse werden als Teil der Arbeit mit dem Titel "Vergleichende Wirtschafts- und Nachhaltigkeitsanalyse der Nutzung von fossilen und elektrischen Fahrzeugen in der Türkei und Europa" verwendet. Diese Forschung wird als wichtige Datenquelle dienen, um die wirtschaftlichen und Umweltauswirkungen der Nutzung von fossilen und elektrischen Fahrzeugen zu verstehen.

                                    Bitte beachten Sie, dass die Umfrageantworten vertraulich behandelt und nur zu Forschungszwecken verwendet werden.

                                    Wir möchten Sie darüber informieren, dass die Umfrageergebnisse im Jahr 2025 auf dieser Plattform veröffentlicht werden.<br></br>

                                    Nochmals vielen Dank für Ihren wertvollen Beitrag.<br></br>
                                    <b>Forscher:</b> Maschinenbauingenieur Ercan TURGUT <br></br>
                                    <b>Betreuer:</b> Assistenzprofessor Melik Ziya YAKUT
                                    </div>
                                </>
      
                        }


                    </>
                    <select
                        style={{
                            maxWidth: 80
                        }}
                        value={this.state.lang}
                        onChange={(e) => {
                            const { value } = e.target;
                            this.setState({ lang: value })
                        }}
                    >


                        <option value="tr">Türkçe</option>
                        <option value="en">English</option>
                        <option value="de">Deutsch</option>



                    </select>
                </div>

                <form onSubmit={this.handleSubmit}>
                    {sorular.map((soru, index) => this.renderSoruInput(soru, index))}
                    <div>
                        <button style={{ width: "100%", marginBottom: 20 }} type="submit">Gönder</button>
                    </div>
                </form>
            </div>
        );
    }
}
